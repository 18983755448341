import React from "react"
import BlankLayout from "../layouts/blank"
import Seo from "./seo"

const VideoPage = ({ title, path }) => (
  <BlankLayout>
    <Seo title={title} titleTemplate="%s" />
    <video
      aria-hidden="true"
      preload="true"
      autoPlay
      loop
      controls
      muted
      playsInline
      style={{
        background: "#000",
        width: "100vw",
        height: "100vh",
        objectFit: "contain",
      }}
    >
      <source src={path.replace("{ext}", "webm")} type="video/webm" />
      <source src={path.replace("{ext}", "mp4")} type="video/mp4" />
    </video>
  </BlankLayout>
)

export default VideoPage
