import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="stylesheet" href="/blank.css" />
    </Helmet>
    <main>{children}</main>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
